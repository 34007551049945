$primary-color: #00AAD2;
$gray-color: #EBEDF3;
$dark-color: #002C5E;
$light-brown: #666666;
$flight-color: #e4dcd2;
$think-color: #F7F3F2;
$white-color: #FFF;

@mixin break-point($point) {
  @if $point == desktop-large {
    @media (min-width: 1920px) {
      @content;
    }
  } @else if $point == desktop-small {
    @media (max-width: 1400px) {
      @content;
    }
  } @else if $point == desktop-tiny {
    @media (max-width: 1100px) {
      @content;
    }
  }
  @else if $point == tablet {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: 768px) {
      @content;
    }
  }
}

$ff_regular: "HyundaiRegular","NotoSansArabic", "Arial", "sans-serif";
$ff_medium:  "HyundaiMedium", "NotoSansArabic", "Arial", "sans-serif";


@mixin HyundaiRegular($font-size, $line-height, $letter-spacing, $font-weight, $color) {
  font-family: "HyundaiRegular","NotoSansArabicRegular", "Arial", "sans-serif";
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  color: $color;
}

@mixin HyundaiMedium($font-size, $line-height, $letter-spacing, $font-weight, $color) {
  font-family: "HyundaiMedium", "NotoSansArabicMedium", "Arial", "sans-serif";
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  color: $color;
}

@mixin positionCC {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin positionX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin positionY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin title-h1 {
  @include HyundaiMedium(58px, 64px, normal, 500, #000000);

  @include break-point(mobile) {
    //font-size: 9.3vw;
    font-size: 32px;
    line-height: 1.1;
  }

}

@mixin title-h3 {
  @include HyundaiMedium(30px, 35px, normal, 500, #000000);

  @include break-point(mobile) {
    //font-size: 6.25vw;
    font-size: 20px;
    line-height: 1.25;
  }

}

@mixin text-subtitle {
  @include HyundaiRegular(20px, 30px, normal, 400, #000000);

  @include break-point(mobile) {
    //font-size: 5vw;
    font-size: 16px;
    line-height: 1.62;
  }
}

@mixin text-simple {
  @include HyundaiRegular(16px, 26px, normal, 400, #000000);

  @include break-point(mobile) {
    //font-size: 4.3vw;
    font-size: 14px;
    line-height: 1.29;
  }
}

@mixin text-small {
  @include HyundaiRegular(14px, 18px, normal, 400, #000000);

  @include break-point(mobile) {
    //font-size: 3.75vw;
    font-size: 12px;
    line-height: 1.33;
  }
}
